import {
  ActionIcon,
  Button,
  Modal,
  Skeleton,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getFoldersNames } from "../../utilities/apis/commonApis";
import NewDocumentUploader from "./NewDocumentUploader";
import useMultiFileUpload from "../../hooks/useMultiFileUpload";
import { IconFolder } from "@tabler/icons-react";

const ViewFiles = ({ parent, parentId }) => {
  const [configs, setConfigs] = useState({
    loading: true,
    folders: [],
  });
  const [newFolderName, setNewFolderName] = useState("");
  const [selectedFolder, setSelectedFolder] = useState(null);
  const multiUploadArgs = useMultiFileUpload({
    loadFromParent: true,
    parentId: parentId,
    parent: "agreement",
    folderName: selectedFolder,
  });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    try {
      const getFolders = async () => {
        const response = await apiWrapWithErrorWithData(
          getFoldersNames({ parent, parentId })
        );
        console.log("response : ", response);
        if (response.success) {
          setConfigs({
            loading: false,
            folders: response.folders,
          });
        }
      };
      getFolders();
    } catch (error) {
      console.log("error: ", error);
    }
  }, []);
  console.log("folders: ", configs.folders);
  return (
    <div>
      {configs.loading && (
        <div className="flex flex-col my-4">
          <div className="flex justify-end">
            <Skeleton height={30} radius="md" className="my-1 w-60" />
          </div>
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
          <Skeleton height={30} radius="md" className="my-1 w-full" />
        </div>
      )}
      {!configs.loading && (
        <div>
          <div>
            <div className="flex justify-between">
              <Text className="">{`files for agreement ${parentId}`}</Text>
              <Button onClick={() => setShowModal(true)}> add folder</Button>
            </div>
            {selectedFolder && (
              <Text
                onClick={() => setSelectedFolder(null)}
                className="mb-4 flex gap-4"
              >
                {" "}
                <IconFolder color="#46BDE1" size={25} />{" "}
                <Text>{`${selectedFolder} > `}</Text>
              </Text>
            )}
            {selectedFolder === null ? (
              <div>
                {configs.folders.length > 0 ? (
                  <div>
                    {configs.folders.map((folder) => (
                      <div key={folder}>
                        <div
                          onClick={() => setSelectedFolder(folder)}
                          className="mt-4 flex gap-4"
                        >
                          <IconFolder color="#46BDE1" size={25} />
                          <Text>{folder}</Text>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>no files</div>
                )}
              </div>
            ) : (
              <div>
                <NewDocumentUploader multiUploadArgs={multiUploadArgs} />
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        opened={showModal}
        title="add folder"
        onClose={() => setShowModal(false)}
      >
        <TextInput
          label="add folder"
          placeholder="enter folder name"
          value={newFolderName}
          onChange={(e) => setNewFolderName(e.target.value)}
        />
        <div className="flex justify-end mt-4">
          <Button
            onClick={() => {
              setSelectedFolder(newFolderName);
              setShowModal(false);
            }}
          >
            add
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ViewFiles;
