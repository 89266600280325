/** @format */
import { Doughnut } from 'react-chartjs-2';
import { getBuIdByName } from '../../utilities/apis/commonApis';
import { apiWrapWithErrorWithData } from '../../utilities/apiHelpers';
  
const DoughnutChart = ({ data, method,type, chartOptions, filters, setFilters }) => {
  let chartOptions1
  if (type==="typeOfAgreement") {
    chartOptions1 = {
      responsive: true,
      maintainAspectRatio: false,
      onClick: (event, elements, chart) => {
          console.log("event getting clicked");
          const elementIndex = elements[0].index; // Index of the clicked element
          const label = chart.data.labels[elementIndex].split("/")[0];
          setFilters((prevFilters) => ({
            ...prevFilters,
            typeOfAgreement: label,
          }));   
      },
      plugins: {
        legend: {
          position: "right",
          labels: {
            boxHeight: 10,
            boxWidth: 10,
          },
        },
      },
    };
  }else if (type === "mapBusinessunit") {
    chartOptions1 = {
      responsive: true,
      maintainAspectRatio: false,
      onClick: async(event, elements, chart) => {
          console.log("event getting clicked");
          const elementIndex = elements[0].index; // Index of the clicked element
          const label = chart.data.labels[elementIndex].split("/")[0]; // Access the label from chart data
          const response = await apiWrapWithErrorWithData( getBuIdByName( {buName: label}));
          setFilters((prevFilters) => ({
            ...prevFilters,
            mapBusinessunit: response.id + "",
          }));
      },
      plugins: {
        legend: {
          position: "right",
          labels: {
            boxHeight: 10,
            boxWidth: 10,
          },
        },
      },
    };
  } else if(
    type === "mapRegion"
  ){
    chartOptions1 = {
      responsive: true,
      maintainAspectRatio: false,
      onClick: async(event, elements, chart) => {
          console.log("event getting clicked");
          const elementIndex = elements[0].index; // Index of the clicked element
          const label = chart.data.labels[elementIndex].split("/")[0]; // Access the label from chart data
          const response = await apiWrapWithErrorWithData( getBuIdByName( {buName: label}));
          setFilters((prevFilters) => ({
            ...prevFilters,
            mapBusinessunit: response.id + "",
          }));
      },
      plugins: {
        legend: {
          position: "right",
          labels: {
            boxHeight: 10,
            boxWidth: 10,
          },
        },
      },
    };
  }
  

  return (
    <>
      {data?.length ? (
        <>
          {/* Optional total count display */}
          {/* <div>
            <span>Total: <span style={{ fontWeight: 'bold'}}>{totalCountChart(data)}</span></span>
          </div> */}
          <Doughnut data={method(data)} options={chartOptions1} placeholder="tt" />
        </>
      ) : (
        <p style={{ textAlign: "center" }}>No data</p>
      )}
    </>
  );
};


export default DoughnutChart;
