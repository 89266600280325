import axios from "axios";
import { headersProvider } from "../apiHelpers";
import constants from "../constants";

export const getPreSignedUrl = ({ extension, contentType, folderName }) =>
  axios.post(
    `${constants.utilityHost}/files/request-upload-url`,
    {
      extension,
      contentType,
    },
    {
      headers: headersProvider(),
    }
  );

export const getReadPresignedUrl = ({ parent, parentId, hostedLink }) =>
  axios.get(`${constants.utilityHost}/files/file-read`, {
    params: {
      parent,
      parentId,
      hostedLink,
    },
    headers: headersProvider(),
  });

export const attachFileToParent = ({
  folderName,
  parent,
  parentId,
  fileName,
  extension,
  mimeType,
  hostedLink,

}) =>
  axios.post(
    `${constants.utilityHost}/files/attach-file`,
    {
      parent,
      parentId,
      fileName,
      extension,
      mimeType,
      hostedLink,
      folderName
    },
    {
      headers: headersProvider(),
    }
  );

export const deleteFileApi = ({ fileId }) =>
  axios.post(
    `${constants.utilityHost}/files/delete-file`,
    {
      fileId,
    },
    {
      headers: headersProvider(),
    }
  );

export const getParentFiles = ({ parent, parentId, folderName }) =>
  axios.get(`${constants.utilityHost}/files/get-parent-files`, {
    headers: headersProvider(),
    noTrailingSlash: true,
    params: {
      parentId,
      parent,
      folderName,
    },
  });

export const searchApi = ({ searchInput }) =>
  axios.post(
    `${constants.utilityHost}/dashboard/search`,
    {
      searchInput,
    },
    {
      headers: headersProvider(),
    }
  );

export const getBuIdByName = ({ buName }) =>
  axios.post(
    `${constants.utilityHost}/getBuId`,
    {
      buName,
    },
    {
      headers: headersProvider(),
    }
  );

export const getFoldersNames = ({ parent, parentId }) =>
  axios.post(
    `${constants.utilityHost}/getFoldersName`,
    {
      parent,
      parentId,
    },
    {
      headers: headersProvider(),
    }
  );
