import React, { useEffect, useState } from "react";
import {
  Select,
  TextInput,
  Button,
  Modal,
  Text,
  Divider,
  SegmentedControl,
  Table,
  Alert,
  Group,
  Stepper,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import flat from "flat";
import { DatePicker } from "@mantine/dates";
import { formatISO, parseISO } from "date-fns";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { apiWrapWithErrorWithData } from "../../../utilities/apiHelpers";
import {
  getAgreementTitleApi,
  getRegisterUserApi,
  bulkAgreementUploadApi,
  createBulkAgreements,
  getAgreementTypesApi,
  getTemplateTypesApi,
  getOrgDetails,
} from "../../../utilities/apis/agreements";
import TemplatesList from "../../../components/TemplateManager/TemplatesList";
import { downloadCsvApi } from "../../../utilities/apis/templates";
import styles from "./AgreementBulkCreate.module.css";
import colors from "../../../utilities/design";
import AgreementBulkParty from "./AgreementBulkParty";
import AgreementBulkConfirm from "./AgreementBulkConfirm";
import AgreementBulkSteps from "./AgreementBulkSteps";
import { getPartiesList } from "../../../utilities/apis/parties";
import { handleChangeUpload } from "./utils";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../redux/selectors";
import {
  getBussinessRegions,
  getBussinessUnit,
  getUserRolesUsingRoleId,
} from "../../../utilities/apis/serviceProvider";
import { camelCaseToTitle } from "../../../utilities/utilities";
import { Search } from "tabler-icons-react";
import { Dropzone } from "@mantine/dropzone";
import { IconUpload } from "@tabler/icons-react";
// import { parse } from "json2csv";

const AgreementBulkCreate = () => {
  const currentUser = useSelector(selectUserData);
  const reminderLabels = {
    15: "15 days",
    30: "30 days",
    45: "45 days",
    60: "60 days",
  };
  const navigate = useNavigate();
  

  const [step, setStep] = useState({ lastStep: false });

  const [typeOfAgreementData, setTypeOfAgreementData] = useState([]);
  const [agreementTitleData, setAgreementTitleData] = useState([]);
  const [businessUnitsOptions, setBusinessUnitsOptions] = useState([]);
  const [requestingUserData, setRequestingUserData] = useState(null);
  const [file, setFile] = useState(null);
  const [showupload, setShowUpload] = useState(false);
  const [intialConfigs, setInitialConfigs] = useState({
    titleOfAgreement: "",
    typeOfAgreement: "",
    dateOfContract: "",
    noOfMonths: "",
    noOfYears: "",
    reminderTime: 0,
    count: 2,
    businessUnit: 1,
    templateId: 0,
    viewTemplate: false,
    editorContent: null,
    mapRegion: 1,
  });
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [templateTypes, setTemplateTypes] = useState([]);
  const [orgDetails, setOrgDetails] = useState({
      address: "",
      name: "",
      entityType: "Company",
    });
    useEffect(() => {
        const getOrganizationDetails = async () => {
          const resp = await apiWrapWithErrorWithData(getOrgDetails());
          if (resp.success) {
            setOrgDetails({
              address: resp.organizationDetails.orgData.regAddress,
              entityType: resp.organizationDetails.orgData.entityType,
              name: resp.organizationDetails.orgName,
            });
          }
        };
        getOrganizationDetails();
      }, []);

  useEffect(() => {
    const getAgreementTypes = async () => {
      const resp = await apiWrapWithErrorWithData(getAgreementTypesApi());
      console.log("1: ", resp);
      setTypeOfAgreementData(resp.typesOfAgreement);
    };
    getAgreementTypes();
    const getTemplateTypes = async () => {
      const resp = await apiWrapWithErrorWithData(getTemplateTypesApi());
      setTemplateTypes(resp.templates);
    };
    getTemplateTypes();
  }, []);
  useEffect(() => {
    const getAgreementTitle = async () => {
      const resp = await apiWrapWithErrorWithData(getAgreementTitleApi());
      console.log("2 : ", resp);
      setAgreementTitleData(resp.titlesOfAgreement);
    };
    getAgreementTitle();
  }, []);
  useEffect(() => {
    const getRegisterUser = async () => {
      const res = await apiWrapWithErrorWithData(getRegisterUserApi());
      setRequestingUserData({
        requestingUserName: res.requestingUser.name,
        requestingUserEmail: res.requestingUser.email,
        requestingUserPhone: res.requestingUser.phone,
        requestingUserDesignation: res.requestingUser.designation,
        requestingUserBusinessDepartment: res.requestingUser.businessDepartment,
      });
      console.log("3: ", requestingUserData);
    };
    getRegisterUser();
  }, []);

  useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        const { data } = await getBussinessUnit();
        if (data) {
          setBusinessUnitsOptions(
            data.businessunitList.map((unit) => ({
              value: unit.id + "",
              label: unit.name,
            })) || []
          );
        } else {
          console.error("Invalid business unit response format:", data);
        }
      } catch (error) {
        console.error("Error fetching business units:", error);
      }
    };
    fetchBusinessUnits();
  }, []);

  const downloadCsvHandler = async () => {
    // if (!formValidation()) return;

    if (intialConfigs.templateId === 0) {
      showNotification({
        title: "Bulk Agreement Form",
        message: "Please Select the agreement template",
        color: "red",
      });
      return;
    }

    const res = await apiWrapWithErrorWithData(
      downloadCsvApi({ templateId: intialConfigs.templateId })
    );
    if (res.success) {
      const { data } = res;
      const fields =
        data.fieldName && data.fieldName.length
          ? data.fieldName?.map((ele) => `ph_${ele}`)
          : [];
      let csvContent = `data:text/csv;charset=utf-8,`;

      let columns = [
        "businessUnit",
        "templateId",
        "typeOfAgreement",
        "titleOfAgreement",
        "requestingUserName",
        "requestingUserEmail",
        "dateOfContract",
        "noOfYears",
        "noOfMonths",
        "reminderTimeInDays",
        "agreementValue",
        "jobOrder",
        "firstPartyEntityType",
        "firstPartyName",
        "firstPartyAddress",
        "firstPartyPoc",
        "firstPartyPocEmail",
        "secondPartyEntityType",
        "secondPartyName",
        "secondPartyBrandName",
        "secondPartySignatory",
        "secondPartyEmail",
        "secondPartyAddress",        
        "secondPartyPoc",
        "secondPartyPocEmail",
        "agreementValue",
        "mapRegion",
      ];
      const columnsHead = columns.map((ele) => camelCaseToTitle(ele));
      csvContent += columnsHead.join(",") + ",";
      csvContent += fields.length ? fields?.join(",") + "\n" : "\n";
      const preFiledValues = {
        ...requestingUserData,
        typeOfAgreement: intialConfigs.typeOfAgreement,
        titleOfAgreement: intialConfigs.titleOfAgreement,
        dateOfContract: intialConfigs.dateOfContract,
        noOfMonths: intialConfigs.noOfMonths,
        noOfYears: intialConfigs.noOfYears,
        templateId: intialConfigs.templateId,
        businessUnit: intialConfigs.businessUnit,
        reminderTimeInDays: intialConfigs.reminderTime,
        agreementType: intialConfigs.agreementType,
        signatureType: intialConfigs.signatureType,
        mapRegion: intialConfigs.mapRegion,
        firstPartyName: orgDetails.name,
        firstPartyAddress: orgDetails.address,
        firstPartyEntityType: orgDetails.entityType,
        requestingUserName: currentUser.name,
        requestingUserEmail: currentUser.email,
      };

      let preFiledContent = "";
      columns.forEach((ele) => {
        if (preFiledValues[ele]) {
          preFiledContent += `${preFiledValues[ele]},`;
        } else {
          preFiledContent += ",";
        }
      });

      for (let i = 0; i < intialConfigs.count; i++) {
        csvContent += `${preFiledContent}\n`;
      }

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${data.name}.csv`);
      document.body.appendChild(link);
      link.click();
      setShowUpload(true);
    }
  };
  function validate() {
    if (flowJson["7"]?.length > 0) {
      return true;
    } else if (flowJson["7"].length === 0) {
      return false;
    }
  }
  const uploadHandler = async (files) => {
    setFile(files[0]);
    console.log("file: ", files[0]);
    let filetosend = files[0];
    const [csvData, formData] = await handleChangeUpload({ file: filetosend });

    console.log("csvData: ", csvData);

    setFormData(csvData);

    console.log("form Data: ", formData);
    // setStep({ lastStep: true });
  };

  const [flowJson, setFLowJson] = useState({
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    sendfordirectsign: false,
  });
  const loginAsId = Number(currentUser.loginAs.id);
  const [signauthData, setSignauthData] = useState([]);
  const [businessRegionOptions, setBusinessRegionOptions] = useState([]);
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const { data } = await getBussinessRegions();
        if (data) {
          setBusinessRegionOptions(
            data.businessRegionList.map((unit) => ({
              value: unit.id + "",
              label: unit.name,
            })) || []
          );
        }
      } catch {
        console.log("error fetching business regions");
      }
    };
    fetchRegions();
    const getSignauth = async () => {
      const signauths = await getUserRolesUsingRoleId(7);
      setSignauthData(signauths.data.userList);
      console.log(signauthData);
    };
    getSignauth();
  }, []);

  useEffect(() => {
    if (loginAsId === 3) {
      setFLowJson({
        3: [
          {
            userId: currentUser.id,
            isComplete: false,
          },
        ],
        4: [],
        5: [],
        6: [],
        7: [],
        sendfordirectsign: true,
      });
    } else if (loginAsId === 4) {
      setFLowJson({
        3: [],
        4: [
          {
            userId: currentUser.id,
            isComplete: false,
          },
        ],
        5: [],
        6: [],
        7: [],
        sendfordirectsign: false,
      });
    }
  }, [loginAsId]);

  // console.log("flowJson: ", flowJson);
  // console.log("intial COnfig: ", intialConfigs);

  // console.log("formDAta: ", formData);
  // console.log("step: ", step);

  // console.log("files: ", file);

  const back = () => {
    setStep({ lastStep: false });
  };

  const onSubmit = async () => {
    setLoading(true);

    if (validate()) {
      try {
        const formDataObject = new FormData();
        formDataObject.append("file", file);
        formDataObject.append("flowJson", JSON.stringify(flowJson));

        const respo = await createBulkAgreements({ formDataObject });

        console.log("response: ", respo);

        setLoading(false);
        navigate("/app/agreements_manager/agreements-list");
      } catch (error) {
        setLoading(false);
        console.log("error: ", error);
        showNotification({
          title: "Create Bulk Agreements",
          color: "red",
          message: "Could not create bulk agreements. Please try again.",
        });
      }
    } else {
      setLoading(false);
      showNotification({
        title: "Validation Error",
        color: "red",
        message: "Please select sign-auth to complete flowJson.",
      });
    }
  };

  return (
    <div className="mx-2">
      <Stepper
        active={
          formData
            ? 2
            : !formData && showupload
            ? 1
            : !formData && !showupload
            ? 0
            : 0
        }
      >
        {/* {!step.lastStep && (
        <div> */}
        {/* {!showupload ? ( */}
        {/* <> */}
        <Stepper.Step label="enter key details">
          <div className="mx-4">
            <Text
              style={{
                color: "#46BDE1",
              }}
              className=" mt-4"
            >
              fill in the key details and download the CSV template:
            </Text>
            <div className="w-full flex flex-col my-4">
              <div>
                <div className="grid grid-cols-2 my-4">
                  <Select
                    required
                    searchable
                    creatable
                    className="max-w-lg"
                    label="type of agreement"
                    placeholder="select type of agreement"
                    data={typeOfAgreementData}
                    onChange={(val) => {
                      setInitialConfigs({
                        ...intialConfigs,
                        typeOfAgreement: val,
                      });
                    }}
                    value={intialConfigs.typeOfAgreement}
                  />
                  <Select
                    required
                    searchable
                    creatable
                    className="max-w-lg"
                    label="title of agreement"
                    placeholder="select title of agreement"
                    data={agreementTitleData}
                    value={intialConfigs.titleOfAgreement}
                    onChange={(val) => {
                      setInitialConfigs({
                        ...intialConfigs,
                        titleOfAgreement: val,
                      });
                    }}
                  />
                </div>
                <div className="grid grid-cols-2 my-4">
                  <DatePicker
                    required
                    className="max-w-lg"
                    label="date of contract"
                    placeholder="select date"
                    onChange={(val) =>
                      setInitialConfigs({
                        ...intialConfigs,
                        dateOfContract: formatISO(val),
                      })
                    }
                    value={intialConfigs.dateOfContract}
                  />
                  <Select
                    required
                    placeholder="reminder time"
                    label="renewal reminder time (in days)"
                    className="max-w-lg"
                    data={[
                      { value: 15, label: reminderLabels["15"] },
                      { value: 30, label: reminderLabels["30"] },
                      { value: 45, label: reminderLabels["45"] },
                      { value: 60, label: reminderLabels["60"] },
                    ]}
                    onChange={(val) =>
                      setInitialConfigs({
                        ...intialConfigs,
                        reminderTime: val,
                      })
                    }
                  />
                </div>
                <div className=" my-4 ">
                  <Text>contract term</Text>
                  <div className="grid grid-cols-2">
                    <div className="grid grid-cols-2 max-w-lg">
                      <div className="mr-4">
                        <div
                          className="flex flex-row justify-between"
                          style={{ width: "200px" }}
                        >
                          <Select
                            required
                            label="duration"
                            placeholder="select years"
                            data={[
                              ...Array.from({ length: 11 }, (_, i) => ({
                                value: `${i}`,
                                label: `${i} year${i === 1 ? "" : "s"}`,
                              })),
                              { value: "100", label: "100 years" },
                            ]}
                            onChange={(val) =>
                              setInitialConfigs({
                                ...intialConfigs,
                                noOfYears: val,
                              })
                            }
                            style={{ width: "160px", marginRight: "20px" }}
                          />
                          <Select
                            label="   "
                            placeholder="select months"
                            data={[
                              { value: "0", label: "0 month" },
                              ...Array.from({ length: 12 }, (_, i) => ({
                                value: `${i + 1}`,
                                label: `${i + 1} month${
                                  i === 0 || i === 1 ? "" : "s"
                                }`,
                              })),
                            ]}
                            onChange={(val) =>
                              setInitialConfigs({
                                ...intialConfigs,
                                noOfMonths: val,
                              })
                            }
                            style={{ width: "160px" }}
                          />
                        </div>
                      </div>
                      <TextInput
                        required
                        className="ml-4"
                        label="agreements count"
                        placeholder="count"
                        type="number"
                        // style={{ width: "200px", marginRight: "20px" }}
                        onChange={(e) =>
                          setInitialConfigs({
                            ...intialConfigs,
                            count: e.target.value,
                          })
                        }
                        value={intialConfigs.count}
                      />
                    </div>
                    {/* <Button
                      style={{
                        backgroundColor: "#46BDE1",
                      }}
                      className="max-w-lg mt-5 mb-0"
                      onClick={(e) =>
                        setInitialConfigs({
                          ...intialConfigs,
                          viewTemplate: true,
                        })
                      }
                    >
                      choose agreement template
                    </Button> */}
                    <div className="flex flex-row justify-end max-w-lg  ">
                      <Select
                        data={templateTypes.map((template) => ({
                          label: template.name,
                          value: template.id,
                        }))}
                        style={{
                          width: "82%",
                        }}
                        label="select template"
                        placeholder="select template"
                        value={intialConfigs.templateId}
                        onChange={(val) =>
                          setInitialConfigs({
                            ...intialConfigs,
                            templateId: val,
                          })
                        }
                      />
                      <Button
                        className="max-w-lg mt-6"
                        color="#46BDE1"
                        // onClick={handleSearchClick} // Call the handler on button click
                        style={{
                          width: "12%",
                          display: "inline-block",
                          marginBottom: "0",
                          // marginTop: "auto",
                          marginLeft: "6%",
                          padding: "0",
                          backgroundColor: "#46BDE1",
                        }}
                        onClick={(e) =>
                          setInitialConfigs({
                            ...intialConfigs,
                            viewTemplate: true,
                          })
                        }
                      >
                        <Search size={20} strokeWidth={3} />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className=" grid grid-cols-2  my-4">
                  <Select
                    required
                    searchable
                    className="max-w-lg"
                    placeholder="enter business unit"
                    label="business unit"
                    data={businessUnitsOptions}
                    dropdownPosition="bottom"
                    onChange={(val) =>
                      setInitialConfigs({
                        ...intialConfigs,
                        businessUnit: val,
                      })
                    }
                  />
                  <Select
                    required
                    placeholder="select sign-auth"
                    label="sign-auth"
                    className="max-w-lg"
                    data={signauthData?.map((user) => ({
                      value: user.id,
                      label: user.name,
                    }))}
                    value={flowJson["7"][0]?.userId}
                    onChange={(val) => {
                      setFLowJson({
                        ...flowJson,
                        7: [{ userId: val, isComplete: false }],
                      });
                    }}
                  />
                  <Select
                    required
                    searchable
                    className="max-w-lg my-4"
                    placeholder="enter business region"
                    label="business region"
                    data={businessRegionOptions}
                    dropdownPosition="bottom"
                    onChange={(val) =>
                      setInitialConfigs({
                        ...intialConfigs,
                        mapRegion: val,
                      })
                    }
                  />
                  <div className="flex  justify-end my-4 max-w-lg">
                    <Button
                      className="flex justify-end mt-6"
                      style={{
                        backgroundColor: "#46BDE1",
                      }}
                      onClick={() => downloadCsvHandler()}
                    >
                      next
                    </Button>
                  </div>
                </div>
              </div>
              {/* <Divider className="my-4" /> */}
            </div>
          </div>
        </Stepper.Step>
        {/* </> */}
        {/* // ) : ( */}
        <Stepper.Step label="upload csv file">
          <div>
            <Text
              style={{
                color: "#46BDE1",
              }}
              className=" mt-4"
            >
              upload the completed CSV file for validation:
            </Text>
            <div className={styles["upload-wrapper"]}>
              <Dropzone
                onDrop={(files) => {
                  uploadHandler(files);
                }}
                style={{ width: "80%", marginTop: "5%" }}
              >
                <Group
                  position="center"
                  spacing="xl"
                  style={{ minHeight: 100, pointerEvents: "none" }}
                >
                  <IconUpload
                    // status={status}
                    style={{
                      width: 80,
                      height: 80,
                      color: "black",
                    }}
                  />

                  <div>
                    <Text size="xl" inline>
                      {file === null
                        ? "drag csv file here or click to select csv files"
                        : `${file.name}`}
                    </Text>
                    <Text size="sm" color="dimmed" inline mt={7}></Text>
                  </div>
                </Group>
              </Dropzone>
            </div>

            {formData !== null && (
              <div className="flex justify-end mr-8">
                <Button
                  className="flex justify-end"
                  onClick={() => setStep({ lastStep: true })}
                  style={{
                    backgroundColor: "#46BDE1",
                  }}
                  // disabled={!step.lastStep}
                >
                  next
                </Button>
              </div>
            )}
          </div>
        </Stepper.Step>
        {/* // )} */}
        {/* </div>
      )} */}

        {/* {formData !== null && step.lastStep && ( */}
        <Stepper.Step label="confirm submission">
          <AgreementBulkConfirm
            bulkAgreementData={formData}
            onPrevious={back}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Stepper.Step>
        {/* // )} */}
      </Stepper>
      <Modal
        overflow="inside"
        opened={intialConfigs.viewTemplate}
        onClose={() => {
          setInitialConfigs({ ...intialConfigs, viewTemplate: false });
        }}
        size="calc(80vw)"
      >
        <TemplatesList
          showNewTemplateBtn={false}
          templateType="Agreements"
          useTemplateFunc={(template) => {
            console.log({
              template,
            });
            setInitialConfigs({
              ...intialConfigs,
              templateId: template.id,
              editorContent: template.quillDelta,
              viewTemplate: false,
            });
          }}
        />
      </Modal>
    </div>
  );
};

export default AgreementBulkCreate;
