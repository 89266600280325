/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PasswordInput, TextInput, Button, Text } from '@mantine/core';
import { At, Lock } from 'tabler-icons-react';
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import {
  assetsPrefix, getValueForInput, loadingStates, validateEmail,
} from '../utilities/utilities';
import { apiWrapWithErrorWithData } from '../utilities/apiHelpers';
import { usersLogin, usersMSLogin } from '../utilities/apis/users';
import { setCookiesSession } from '../cookiesSession';
import actions from '../redux/actions';
import styles from '../components/Header/AppHeader.module.css';
import { serviceProviderLoginApi } from '../utilities/apis/serviceProvider';
import { selectIsUserLoggedIn } from '../redux/selectors';
import microsoft from "./ImagesDashboard/microsoft.png";
import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "a842bed3-c339-4bb1-b289-a224269eb5df", // Replace with your Application (client) ID
    authority:
      "https://login.microsoftonline.com/70e98426-3ab5-4b32-99a3-938aea5ef965", // Replace with your Directory (tenant) ID
    redirectUri: "https://mswordapp.truecounsel.in/", // Redirect URI configured in Azure
  },
  cache: {
    cacheLocation: "localStorage", // Can also be "sessionStorage"
    storeAuthStateInCookie: true, // Set to true if needed for IE11/Edge
  },
};
const instance = new PublicClientApplication(msalConfig);


const LoginPage = () => {
  const [configs, setConfigs] = useState({
    modalFormOpen: false,
    loading: loadingStates.NO_ACTIVE_REQUEST,
    requestNoticeFormShow: false,
    email: '',
    password: '',
    errors: {},
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation;
  const { redirectAfterLogin } = state || {};
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await instance.initialize();
        console.log("MSAL initialized successfully.");
      } catch (error) {
        console.error("Error during MSAL initialization:", error);
      }
    };
    initializeMsal();
  }, []);
  const inputHandler = (name) => (input) => {
    const value = getValueForInput(input);
    setConfigs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleMsLogin = async() => {
    try {
      const loginRequest = {
        scopes: ["User.Read"], // Permissions your app will request
      };

      console.log("loginRequest: ", instance);
      const response = await instance.loginPopup(loginRequest);
      console.log("Response of MS Login: ", response);

      // Example: Accessing account or token information
      console.log("Access Token: ", response.accessToken);
      if (response.accessToken) {
        const apiResponse = await apiWrapWithErrorWithData(
          usersMSLogin({ accessToken: response.accessToken })
        );
        if (apiResponse?.success) {
          const trueCounselJwtToken = apiResponse?.token;
          if (trueCounselJwtToken) {
            setCookiesSession(
              "trueCounselUserData",
              JSON.stringify(apiResponse),
              2
            );
            dispatch({
              type: actions.SET_USER_DATA,
              payload: apiResponse.user,
            });

            navigate("/app/agreements_manager");
          }
          setConfigs({ ...configs, loading: loadingStates.NO_ACTIVE_REQUEST });
        } else {
          setConfigs({ ...configs, loading: loadingStates.FAILED });
          alert("Something went wrong. Please try refreshing the page.");
        }
      }
    } catch (error) {
      console.error("Error during MS Login: ", error);
    }
  };
  const responseGoogleSuccess = async (response) => {
    setConfigs({ ...configs, loading: loadingStates.LOADING });
    let token = null;
    try {
      if (response.credential) {
        token = response.credential;
      }
    } catch (e) {
      console.log(e);
      alert('Failed to log in. Please refresh page.');
    }
    if (token) {
      const apiResponse = await apiWrapWithErrorWithData(usersLogin({ accessToken: token }));
      if (apiResponse?.success) {
        const trueCounselJwtToken = apiResponse?.token;
        if (trueCounselJwtToken) {
          setCookiesSession('trueCounselUserData', JSON.stringify(apiResponse), 2);
          dispatch({
            type: actions.SET_USER_DATA,
            payload: apiResponse.user,
          });
          
          navigate("/app/agreements_manager");
        }
        setConfigs({ ...configs, loading: loadingStates.NO_ACTIVE_REQUEST });
      } else {
        setConfigs({ ...configs, loading: loadingStates.FAILED });
        alert('Something went wrong. Please try refreshing the page.');
      }
    }
  };

  useGoogleOneTapLogin({
    onError: (error) => console.log(error),
    onSuccess: (response) => console.log(response),
    googleAccountConfigs: {
      client_id: clientId, // Your google client id here !!!
      callback: responseGoogleSuccess,
    },
    disabled: true,
  });
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  useEffect(() => {
    window.handleGoogleLogin = responseGoogleSuccess;
    if (isUserLoggedIn) {
      navigate(redirectAfterLogin || "/app/agreements_manager");
    }
    return () => {
      window.handleGoogleLogin = () => alert('Login not allowed at this point.');
    };
  }, []);

  const loginHandler = async () => {
    const { email, password } = configs;
    setConfigs((prevState) => ({
      ...prevState,
      errors: {},
    }));
    const keys = {};
    if (!validateEmail(email)) {
      keys.email = 'Invalid Email';
    }
    if (!password || !password.length) {
      keys.password = 'Invalid Password';
    }
    if (Object.keys(keys).length) {
      setConfigs((prevState) => ({
        ...prevState,
        errors: keys,
      }));
      showNotification({
        title: 'Login',
        message: 'Please check inputs.',
        color: 'red',
      });
      return;
    }
    setConfigs((prevState) => ({
      ...prevState,
      loading: loadingStates.LOADING,
    }));
    const resp = await apiWrapWithErrorWithData(serviceProviderLoginApi({
      email: configs.email,
      password: configs.password,
    }));
    if (resp?.success && resp?.token && resp?.user) {
      setConfigs((prevState) => ({
        ...prevState,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      }));
      setCookiesSession('trueCounselUserData', JSON.stringify(resp), 2);
      dispatch({
        type: actions.SET_USER_DATA,
        payload: resp.user,
      });
      navigate(redirectAfterLogin || '/app/choose_manager');
    } else {
      setConfigs((prevState) => ({
        ...prevState,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      }));
      showNotification({
        title: 'Login',
        message: resp.message || "Couldn't login",
        color: 'red',
      });
    }
  };

  return (
    <div className="w-screen h-screen bg-white">
      <div className="flex w-full h-full justify-center items-center">
        <div className="flex flex-col w-80">
          <img className={styles.logo} src={`${assetsPrefix}/images/trueCounselLogo.png`} alt="TrueCounsel" />
          <TextInput
            value={configs.email}
            onChange={inputHandler('email')}
            className="my-2"
            label="Email"
            placeholder="Enter email"
            icon={<At size={14} />}
            error={configs.errors.email}
          />
          <PasswordInput
            value={configs.password}
            onChange={inputHandler('password')}
            className="my-2"
            label="Password"
            placeholder="Enter password"
            icon={<Lock size={14} />}
            error={configs.errors.password}
          />
          <div className='flex justify-end pr-2'>
          <a  
          className='text-sm text-blue-900'
          href='/app/forgotpassword'>
            forgot password?
          </a>
          </div>
          
          <Button
            onClick={(e) => {
              e.stopPropagation();
              loginHandler();
            }}
            disabled={configs.loading === loadingStates.LOADING}
            className="my-2"
          >
            Login
          </Button>
          {/* <div className="flex justify-center my-2 w-full">
            <div
              id="g_id_onload"
              data-client_id={clientId}
              data-callback="handleGoogleLogin"
              data-auto_prompt="false"
            />
            <div
              className="g_id_signin"
              data-type="standard"
              data-size="large"
              data-theme="outline"
              data-text="sign_in_with"
              data-shape="rectangular"
              data-logo_alignment="left"
            />
          </div> */}

          <button
            style={{ height: "44px" }}
            className="flex  border-2 rounded"
            onClick={handleMsLogin}
          >
            <div style={{width: "44px", height: "40px" , padding: "10px", }} className="border-r-2">
              <img src={microsoft} style={{width: "20px", height: "20px"}}  alt="microsoft" />{" "}
            </div>
            <div style={{backgroundColor: "rgb(0 0 0 / 72%)"}} className=" h-full w-full">
            <span className="ml-4  my-auto text-white font-bold">sign in with microsoft</span>
            </div>
          </button>
          
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
