import React, { useEffect, useState } from "react";
import { Button, Group, Stepper } from "@mantine/core";

import { addDays, parseISO, subDays } from "date-fns";
import flat from "flat";
import { Progress } from "@mantine/core";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../redux/selectors";
import {
  createAgreement,
  getDocsToken,
  getOrgDetails,
  getPublicUrl,
  putLegacyFlowJson,
} from "../../../utilities/apis/agreements";
import { apiWrapWithErrorWithData } from "../../../utilities/apiHelpers";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { attachFileToParent } from "../../../utilities/apis/commonApis";
import { camelCaseToTitle } from "../../../utilities/utilities";
import constants from "../../../utilities/constants";
import { showNotification } from "@mantine/notifications";
import { Dropzone } from "@mantine/dropzone";
import { IconUpload } from "@tabler/icons-react";
import { handleChangeUpload } from "../BulkAgreement/utils";

const CreateBulkLegacyAgreement = () => {
  const [value, setValue] = useState(0);
  const currentUser = useSelector(selectUserData);
  const navigate = useNavigate();
  let columns = [
    "typeOfAgreement",
    "relationshipType",
    "relationshipWith",
    "JobOrder",
    "titleOfAgreement",
    "requestingUserName",
    "requestingUserEmail",
    "dateOfContract",
    "noOfYears",
    "noOfMonths",
    "reminderTimeInDays",
    "location",
    "mapregion",
    "agreementvalue",
    "firstPartyEntityType",
    "firstPartyName",
    "secondPartyType",
    "secondPartyEntityType",
    "secondPartyName",
    "secondPartyBrandName",
    "fileName",
    "folderId",
  ];

  const [flowJsonData, setFlowJsonData] = useState({});
  useEffect(() => {
    if (currentUser.loginAs.id === 3) {
      setFlowJsonData({
        3: [{ userId: currentUser.id, isLegacy: true, isComplete: true }],
        4: [],
        5: [],
        6: [],
        7: [],
      });
    } else if (currentUser.loginAs.id === 4) {
      setFlowJsonData({
        3: [],
        4: [{ userId: currentUser.id, isLegacy: true, isComplete: true }],
        5: [],
        6: [],
        7: [],
      });
    }
  }, []);
  console.log(flowJsonData);
  console.log(currentUser);
  const [orgDetails, setOrgDetails] = useState({
    address: "",
    name: "",
    entityType: "Company",
  });
  // const [requestingUserData, setRequestingUserData] = useState(null);
  useEffect(() => {
    const getOrganizationDetails = async () => {
      const resp = await apiWrapWithErrorWithData(getOrgDetails());
      if (resp.success) {
        setOrgDetails({
          address: resp.organizationDetails.orgData.regAddress,
          entityType: resp.organizationDetails.orgData.entityType,
          name: resp.organizationDetails.orgName,
        });
      }
    };
    getOrganizationDetails();
  }, []);

  const handleDownload = () => {
    let csvContent = `data:text/csv;charset=utf-8,`;
    const columnsHead = columns.map((ele) => ele);
    csvContent += columns.join(",") + "\r\n";
    // csvContent += fields.length ? fields?.join(",") + "\n" : "\n";
    const preFiledValues = {
      // ...requestingUserData,
      firstPartyName: orgDetails.name,
      firstPartyAddress: orgDetails.address,
      firstPartyEntityType: orgDetails.entityType,
      requestingUserName: currentUser.name,
      requestingUserEmail: currentUser.email,
    };

    let preFiledContent = "";
    columns.forEach((ele) => {
      if (preFiledValues[ele]) {
        preFiledContent += `${preFiledValues[ele]},`;
      } else {
        preFiledContent += ",";
      }
    });

    for (let i = 0; i < 10; i++) {
      csvContent += `${preFiledContent}\n`;
    }

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `bulk_legacy_upload.csv`);
    document.body.appendChild(link);
    link.click();
  };
  let x = 0;

  useEffect(() => {
    if (value === 100) {
      navigate("/app/agreements_manager/docs-locker");
    }
  }, [value]);

  const handleFileChange = async (files) => {
    try {
      console.log("file : ", files[0]);
      const file = files[0];
      const [csvData, formData] = await handleChangeUpload({ file });
      console.log("csvData: ", csvData);
      let filesData = null;
      for (let index = 0; index < csvData.length; index++) {
        const folder_id = csvData[index].folderId;
        const fileName = csvData[index].fileName;

        if (!filesData) {
          const url = `https://graph.microsoft.com/v1.0/users/${constants.onedriveUserId}/drive/items/${folder_id}/children`;
          const accessToken = await apiWrapWithErrorWithData(getDocsToken());
          const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${accessToken.data}` },
          });
          console.log("Files:", response.data);

          filesData = response?.data?.value;
        }
        console.log("filesData: ", filesData);
        // const files = response.data.files;
        const file = filesData.find((obj) => obj.name === fileName);
        console.log("file : ", file);
        if (file) {
          let month, year, day;
          [day, month, year] = csvData[index].dateOfContract.split("/");
          const formattedDate = `${year}-${month.padStart(
            2,
            "0"
          )}-${day.padStart(2, "0")}T00:00:00.000Z`;
          const contractMonths = csvData[index].noOfMonths
            ? parseInt(csvData[index].noOfMonths, 10) * 30
            : null;
          const contractYears = csvData[index].noOfYears
            ? parseInt(csvData[index].noOfYears, 10) * 365
            : null;
          const totalDays = contractMonths + contractYears;

          const contractDueDate = addDays(parseISO(formattedDate), totalDays);
          console.log("form values tk aa gya");
          const formValues = {
            typeOfAgreement: csvData[index].typeOfAgreement,
            titleOfAgreement: csvData[index].titleOfAgreement,
            requestingUserDetails: {
              name: csvData[index].requestingUserName,
              email: csvData[index].requestingUserEmail,
            },
            dateOfContract: formattedDate,
            contractTermInDays: totalDays,
            noOfYears: parseInt(csvData[index].noOfYears, 10),
            noOfMonths: parseInt(csvData[index].noOfMonths, 10),
            location: csvData[index].location,
            agreementValue: parseInt(csvData[index].agreementValue),
            mapRegion: csvData[index].mapRegion + "",
            jobOrder: csvData[index].jobOrder,
            reminderTimeInDays: parseInt(csvData[index].reminderTimeInDays, 10),
            dueDateOfContract: contractDueDate,
            completionDate: formattedDate,
            reminderTime: subDays(
              contractDueDate,
              csvData[index].reminderTimeInDays
            ),
            contractQuillJsDelta: csvData[index].contractQuillJsDelta,
            firstParty: {
              name: csvData[index].firstPartyName,
              type: csvData[index].firstPartyType,
              entityType: csvData[index].firstPartyEntityType,
            },
            secondParty: {
              name: csvData[index].secondPartyName,
              type: csvData[index].secondPartyType,
              brandName: csvData[index].secondPartyBrandName,
              entityType: csvData[index].secondPartyEntityType,
            },
            thirdParty: {},
            fourthParty: {},
            fifthParty: {},
            formCompleted: true,
            relationshipType: csvData[index].relationshipType,
            relationshipWith: csvData[index].relationshipWith,
            businessUnit: currentUser.mapBusinessunit,
            status: "SIGNED",
          };
          console.log("formValues: :", formValues);
          const agreementData = {
            json: flat.unflatten({ ...formValues, isLegacy: true }),
            flatted: { ...formValues, isLegacy: true },
          };

          const respo = await apiWrapWithErrorWithData(
            createAgreement({ agreementData, ...formValues })
          );
          await apiWrapWithErrorWithData(
            putLegacyFlowJson(respo.agreement.id, flowJsonData)
          );
          // console.log("response: ", respo);

          //generate link using  use this in api
          // const link = await apiWrapWithErrorWithData(
          //   getPublicUrl({ fileId: file.id, folderId: folder_id })
          // );
          // if (link.success) {
            const filetoAttach = {
              parent: "agreement",
              parentId: respo.agreement.id + "",
              fileName: fileName,
              hostedLink: "onedrive" + file.id,
              mimeType: file.file.mimeType,
              extension: ".pdf",
              folderName: "signed",
            };
            console.log("file to attach is: ", filetoAttach);
            const response = await apiWrapWithErrorWithData(
              attachFileToParent({
                ...filetoAttach,
              })
            );
            if (!response.success) {
              showNotification({
                message: "file does not exist for item: " + `${index + 1}`,
                color: "red",
                title: "could upload agreement " + `${index + 1}`,
              });
              break;
            }else {
              x = (index + 1) / csvData.length;
            }
            
          // } else{
          //   showNotification({
          //     message: "file does not exist for item: " + `${index + 1}`,
          //     color: "red",
          //     title: "could upload agreement " + `${index + 1}`,
          //   });
          //   break;
          // }
         

          
        } else {
          showNotification({
            message: "file does not exist for item: " + `${index + 1}`,
            color: "red",
            title: "could upload agreement " + `${index + 1}`,
          });
          break;
        }
        setValue(x * 100);
        console.log(x);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-4">
        <h1 className="text-center text-3xl font-bold text-gray-800 mb-4">
          create bulk legacy agreements
        </h1>
        <h3 className="text-lg text-gray-600 mb-2">
          download CSV file by clicking on download CSV button.
        </h3>
        <Button
          className="text-white   hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 transform hover:-translate-y-0.5 transition-all duration-300"
          onClick={handleDownload}
          style={{ backgroundColor: "#46BDE1" }}
        >
          download CSV
        </Button>
        <h3 className="text-lg text-gray-600 mt-4 mb-2">
          fill all the required fields and upload the CSV file.
        </h3>
        <div
          className="flex w-full justify-center items-center mt-2 rounded"
          // style={{ backgroundColor: "#46BDE1" }}
        >
          <Dropzone
            onDrop={(files) => {
              handleFileChange(files);
            }}
            style={{ width: "80%", marginTop: "5%" }}
          >
            <Group
              position="center"
              spacing="xl"
              style={{ minHeight: 100, pointerEvents: "none" }}
            >
              <IconUpload
                // status={status}
                style={{
                  width: 80,
                  height: 80,
                  color: "black",
                }}
              />

              {/* <div>
                              <Text size="xl" inline>
                                {file === null
                                  ? "drag csv file here or click to select csv files"
                                  : `${file.name}`}
                              </Text>
                              <Text size="sm" color="dimmed" inline mt={7}></Text>
                            </div> */}
            </Group>
          </Dropzone>
        </div>
      </div>
      <Progress
        className="mt-20 mx-auto"
        style={{ width: "70%" }}
        radius="lg"
        size="lg"
        value={value}
        striped
        animated
      />
    </>
  );
};

export default CreateBulkLegacyAgreement;
