import { Button, Text } from "@mantine/core";
import React, { useState } from "react";
import NewLegacyDocumentUploader from "../../components/NewDocumentUploader/NewDocumentLegacy";
import useMultiFileUpload from "../../hooks/useMultiFileUpload";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { putLegacyFlowJson } from "../../utilities/apis/agreements";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";
import ViewFiles from "../../components/NewDocumentUploader/ViewFiles";

const LegacyAgreementForm4 = ({
  uiConfigs,
  setUiConfigs,
  legacyAgreementFormValue,
  setLegacyAgreementFormValue,
  agreementId,
  saveAgreement,
}) => {
  const [selectedFile, setSelectedFile] = useState(false);
  const navigate = useNavigate();
  const currentUser = useSelector(selectUserData) || {};
  console.log(currentUser);
  let flowJsonData;
  if (currentUser.loginAs.id === 3) {
    flowJsonData = {
      3: [{ userId: currentUser.id, isLegacy: true }],
      4: [],
    };
  } else if (currentUser.loginAs.id === 4) {
    flowJsonData = {
      3: [],
      4: [{ userId: currentUser.id, isLegacy: true }],
    };
  }
  console.log("flowjsonData", flowJsonData);

  const savePrev = async () => {
    setUiConfigs({
      ...uiConfigs,
      currentStep: 1,
    });
  };

  const saveNext = async () => {
    
      setLegacyAgreementFormValue({
        ...legacyAgreementFormValue,
        formCompleted: true,
      });
      await apiWrapWithErrorWithData(
        putLegacyFlowJson(agreementId, flowJsonData)
      );
      await saveAgreement();

      showNotification({
        color: "green",
        title: "Agreement Form",
        message: "Agreement Created",
      });
      navigate("/app/agreements_manager/agreements-list");
    
  };
  return (
    <>
      {/* <div 
    
    >
  
      <input
        type="file"
        id="fileInput"
        className="flex"
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".pdf"
      />
      <button 
      type="button"
      className="flex center mx-auto text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
      onClick={handleButtonClick}>Select Agreement</button>
      {selectedFile && <p className="text-center">Selected File: {selectedFile.name}</p>}
    </div> */}

      {/* <div className="p-6">
        <NewLegacyDocumentUploader
          actionText={null}
          setSelectedFile={setSelectedFile}
          multiUploadArgs={multiUploadArgs}
        />
      </div> */}

      <ViewFiles parent={"agreement"} parentId={agreementId} />

      <div className="flex flex-row justify-between">
        <Button
          className="max-w-xs mt-20"
          onClick={savePrev}
          style={{
            backgroundColor: "#46BDE1",
          }}
        >
          previous
        </Button>
        <Button
          className="max-w-xs mt-20"
          onClick={saveNext}
          style={{
            backgroundColor: "#46BDE1",
          }}
        >
          finish
        </Button>
      </div>
    </>
  );
};
export default LegacyAgreementForm4;
