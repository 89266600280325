import React, { useEffect, useState } from "react";
import flat from "flat";
import { Stepper } from "@mantine/core";
import { useForm } from "@mantine/form";
import { loadingStates } from "../../utilities/utilities";
import colors, { themeColor } from "../../utilities/design";
import LegacyAgreementForm1 from "./LegacyAgreementForm1";
import AgreementForm2 from "./AgreementForm2";
import AgreementForm3 from "./AgreementForm3";
import AgreementForm1 from "./AgreementForm1";
import {
  createAgreement,
  getAgreementTitleApi,
  getAgreementTypesApi,
  updateAgreement,
} from "../../utilities/apis/agreements";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import LegacyAgreementForm2 from "./LegacyAgreementForm2";
import LegacyAgreementForm3 from "./LegacyAgreementForm3";
import LegacyAgreementForm4 from "./LegacyAgreementForm4";
import { addDays, parseISO, subDays } from "date-fns";

const CreateNewLegacyAgreement = () => {
  const FORM_STEPS = {
    STEP_1: 0,
    STEP_2: 1,
    STEP_3: 2,
  };

  const [uiConfigs, setUiConfigs] = useState({
    currentStep: FORM_STEPS.STEP_1,
    loading: loadingStates.NO_ACTIVE_REQUEST,
  });

  const [legacyAgreementFormValue, setLegacyAgreementFormValue] = useState({
    typeOfAgreementData: [],
    typeOfAgreement: null,
    titleOfAgreementData: [],
    titleOfAgreement: null,
    jobOrder: "",
    agreementValue: 0,
    relationshipType: "",
    relationshipWith: "",
    requestingUser: {
      name: "",
      email: "",
      phone: "",
      designation: "",
      businessDepartment: "",
    },
    contractQuillJsDelta: null,
    dateOfContract: null,
    noOfMonths: null,
    noOfYears: "1",
    reminderTimeInDays: 15,
    isLegacy: true,
    firstParty: {},
    secondParty: {},
    thirdParty: {},
    fourthParty: {},
    fifthParty: {},
    documents: [],
    fileSelected: false,
    formCompleted: false,
    location: null,
    businessUnit: "1",
    mapRegion: "1",
    status: "SIGNED",
    year: 2024,
    month: "Jan",
  });

  const getAgreementTypes = async () => {
    const resp = await apiWrapWithErrorWithData(getAgreementTypesApi());
    if (resp?.success && resp?.typesOfAgreement) {
      const uniqueValues = Array.from(new Set([...resp.typesOfAgreement]));
      setLegacyAgreementFormValue((prevState) => ({
        ...prevState,
        typeOfAgreementData: uniqueValues,
      }));
    } else {
      // showNotification({
      //   title: 'Agreement Types',
      //   message: 'Could not load agreement types',
      //   color: 'red',
      // });
    }
  };
  const getAgreementTitle = async () => {
    const resp = await apiWrapWithErrorWithData(getAgreementTitleApi());
    if (resp?.success && resp?.titlesOfAgreement) {
      const uniqueValues = Array.from(new Set([...resp.titlesOfAgreement]));
      setLegacyAgreementFormValue((prevState) => ({
        ...prevState,
        titleOfAgreementData: uniqueValues,
      }));
    } else {
      // showNotification({
      //   title: 'Agreement Title',
      //   message: 'Could not load agreement titles',
      //   color: 'red',
      // });
    }
  };
  useEffect(() => {
    getAgreementTitle();
    getAgreementTypes();
  }, []);
  const [agreementId, setAgreementId] = useState(null);
  const agreementForm = useForm({
    initialValues: flat(legacyAgreementFormValue),
  });
  console.log("agrement form legacy: ", agreementForm);
  const saveAgreement = async (values) => {
    const agreementData = {
      json: flat.unflatten(values || agreementForm.values),
      flatted: values || agreementForm.values,
    };
    const toUse = agreementId ? updateAgreement : createAgreement;

    const contractMonths = legacyAgreementFormValue.noOfMonths
      ? parseInt(legacyAgreementFormValue.noOfMonths, 10) * 30
      : null;
    const contractYears = legacyAgreementFormValue.noOfYears
      ? parseInt(legacyAgreementFormValue.noOfYears, 10) * 365
      : null;
    const totalDays = contractMonths + contractYears;

    const contractDueDate = addDays(
      parseISO(legacyAgreementFormValue.dateOfContract),
      totalDays
    );
    const agreementArgs = {
      typeOfAgreement: legacyAgreementFormValue.typeOfAgreement,
      titleOfAgreement: legacyAgreementFormValue.titleOfAgreement,
      jobOrder: legacyAgreementFormValue.jobOrder,
      requestingUserDetails: legacyAgreementFormValue.requestingUser,
      dateOfContract: legacyAgreementFormValue.dateOfContract,
      agreementValue: legacyAgreementFormValue.agreementValue,
      contractTermInDays: totalDays,
      noOfYears: parseInt(legacyAgreementFormValue.noOfYears, 10),
      noOfMonths: parseInt(legacyAgreementFormValue.noOfMonths, 10),
      location: legacyAgreementFormValue.location,
      reminderTimeInDays: parseInt(
        legacyAgreementFormValue.reminderTimeInDays,
        10
      ),
      dueDateOfContract: contractDueDate,
      reminderTime: subDays(
        contractDueDate,
        legacyAgreementFormValue.reminderTimeInDays
      ),
      contractQuillJsDelta: legacyAgreementFormValue.contractQuillJsDelta,
      firstParty: legacyAgreementFormValue.firstParty,
      secondParty: legacyAgreementFormValue.secondParty,
      thirdParty: legacyAgreementFormValue.thirdParty,
      fourthParty: legacyAgreementFormValue.fourthParty,
      fifthParty: legacyAgreementFormValue.fifthParty,
      formCompleted: legacyAgreementFormValue.formCompleted,
      relationshipType: legacyAgreementFormValue.relationshipType,
      relationshipWith: legacyAgreementFormValue.relationshipWith,
      businessUnit: legacyAgreementFormValue.businessUnit || "1",
      mapRegion: legacyAgreementFormValue.mapRegion,
      status: legacyAgreementFormValue.status || "SIGNED",
    };
    const args = agreementId
      ? {
          agreementId: agreementId,
          agreementData,
          ...agreementArgs,
        }
      : { agreementData, ...agreementArgs };
    console.log("agreement Args for legacy", agreementArgs);
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.LOADING,
    });
    const response = await apiWrapWithErrorWithData(toUse(args));
    if (response?.success) {
      if (response?.agreement?.id) {
        setAgreementId(response.agreement.id);
      }
      setUiConfigs({
        ...uiConfigs,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
    } else {
      // showNotification({
      //   color: 'red',
      //   title: 'Agreement Form',
      //   message: 'Something went wrong.',
      // });
      setUiConfigs({
        ...uiConfigs,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });

      return false;
    }

    return true;
  };

  return (
    <div>
      <Stepper
        color={themeColor(colors.agreement)}
        active={uiConfigs.currentStep}
        onStepClick={(step) => {
          setUiConfigs({
            ...uiConfigs,
            currentStep: step,
          });
        }}
        breakpoint="sm"
        style={{ paddingRight: "10px" }}
      >
        <Stepper.Step allowStepSelect={false} label="key details">
          <LegacyAgreementForm1
            saveAgreement={saveAgreement}
            setLegacyAgreementFormValue={setLegacyAgreementFormValue}
            legacyAgreementFormValue={legacyAgreementFormValue}
            uiConfigs={uiConfigs}
            setUiConfigs={setUiConfigs}
          />
        </Stepper.Step>

        <Stepper.Step allowStepSelect={false} label="parties">
          <LegacyAgreementForm3
            saveAgreement={saveAgreement}
            setLegacyAgreementFormValue={setLegacyAgreementFormValue}
            legacyAgreementFormValue={legacyAgreementFormValue}
            uiConfigs={uiConfigs}
            setUiConfigs={setUiConfigs}
          />
        </Stepper.Step>
        <Stepper.Step allowStepSelect={false} label="select agreement">
          <LegacyAgreementForm4
            saveAgreement={saveAgreement}
            uiConfigs={uiConfigs}
            agreementId={agreementId}
            setUiConfigs={setUiConfigs}
            legacyAgreementFormValue={legacyAgreementFormValue}
            setLegacyAgreementFormValue={setLegacyAgreementFormValue}
          />
        </Stepper.Step>
      </Stepper>
    </div>
  );
};

export default CreateNewLegacyAgreement;
