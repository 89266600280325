import { showNotification } from "@mantine/notifications";
import Papa from "papaparse";
import * as XLSX from "xlsx";

const handleFileUploadCsv = async (file) => {
    // const file = event.target.files[0];

    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: (result) => {
                resolve(result.data);
            },
            error: (error) => {
                reject("CSV Parsing Error: " + error.message);
            },
        });
    });
};

const readFile = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary" });

            workbook.SheetNames.forEach((sheetName) => {
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { defval: null });
                resolve(jsonData);
            });
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsBinaryString(file);
    });
};

const handleFileUploadExcel = async (file) => {
    

    try {
        const jsonData = await readFile(file);
        return jsonData;
    } catch (error) {
        throw Error(error);
    }
};

const excelToCsvData = (temp) => {
    const header = Object.keys(temp[0]);
    const csv = [header.join(", ")];

    for (const row of temp) {
        const values = header.map((key) => row[key]);
        csv.push(values.join(", "));
    }

    const csvData = csv.join("\n");
    return csvData;
}

export const handleChangeUpload = async ({file}) => {
    console.log("file here is : ", file);
    const fileName = file.name.split(".");
    const fileType = fileName[fileName.length - 1];

    if (!["csv", "xlsx"].includes(fileType.toLowerCase())) {
        showNotification({
            title: "Bulk Agreement Creation",
            message: "Please upload valid file only",
            color: "red",
        });
        throw Error("File format is not supported");
    }

    // try {
        let csvData = [];
        const formData = new FormData();

        if (fileType === "csv") {
            const temp = await handleFileUploadCsv(file);
            const [check] = Object.values(temp[temp.length - 1]);

            if (!check) temp.pop();

            temp.map((ele) => {
                const date = new Date(ele["Date Of Contract"]);
                csvData.push({ ...ele, ["Date Of Contract"]: date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear() });
            });
            formData.append("file", file);
        } else {
            const temp = await handleFileUploadExcel(file);
            csvData = [...temp];

            const csvDataForm = excelToCsvData(temp);

            formData.append(
                "file",
                new Blob([csvDataForm], { type: "text/csv" }),
                "excel_data.csv"
            );
        }

        return [csvData, formData];
    // } catch (error) {
    //     console.log(error);
    // }
};